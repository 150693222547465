import * as React from "react";
import Layout from "../../components/Layout";
import empty_png from "../../images/logo/empty.png";
import ImageScroller from "../../ui-blocks/ImageScroller";
import PrevNextNavFooter from "../../ui-blocks/PrevNextNavFooter/PrevNextNavFooter";

const BrandCampaigns = (props) => {
  return (
    <Layout>
      <div
        className="page-header image-header"
        id="overview"
        data-navindex="1"
        style={{ backgroundColor: "rgb(154, 159, 163)", height: "650px" }}
      >
        <div
          className="content-flex"
          style={{ color: "#fff", top: "", textAlign: "left" }}
        >
          <h1
            className="copy"
            style={{ color: "#fff", top: "", textAlign: "left" }}
          >
            Lorem ipsum dolor sit amet, consecteur adipiscing elit. Cras blandit
            quis felis eget condimentum. Etiam sit amet velit ipsum. Ut in
            porttitor neque. Praesent eget orci erat
          </h1>
        </div>
      </div>

      <section className="component Section">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Brand Campaigns</h2>
          </div>
        </div>
        {/* <div className='blog-content'></div>
        <div className='image'></div> */}
        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu
              dui vivamus arcu felis bibendum. Lacus vestibulum sed arcu non
              odio euismod lacinia at quis. Sagittis purus sit amet volutpat
              consequat mauris nunc congue nisi. Nisl purus in mollis nunc sed
              id semper risus. Erat velit scelerisque in dictum non. Id eu nisl
              nunc mi ipsum faucibus vitae aliquet. Urna et pharetra pharetra
              massa massa. Massa ultricies mi quis hendrerit dolor magna eget
              est. Sodales neque sodales ut etiam sit amet nisl purus in. Erat
              nam at lectus urna duis convallis convallis tellus id. Lacus
              luctus accumsan tortor posuere ac ut consequat.
            </div>
          </div>
        </div>
      </section>

      <section className="component Section" id="approact-construct">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">The Approach (Construct)</h2>
          </div>
        </div>
        {/* <div className='blog-content'></div>
        <div className='image'></div> */}
        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy spaced-p">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu
              dui vivamus arcu felis bibendum. Lacus vestibulum sed arcu non
              odio euismod lacinia at quis. Sagittis purus sit amet volutpat
              consequat mauris nunc congue nisi. Nisl purus in mollis nunc sed
              id semper risus. Erat velit scelerisque in dictum non. Id eu nisl
              nunc mi ipsum faucibus vitae aliquet. Urna et pharetra pharetra
              massa massa. Massa ultricies mi quis hendrerit dolor magna eget
              est. Sodales neque sodales ut etiam sit amet nisl purus in. Erat
              nam at lectus urna duis convallis convallis tellus id. Lacus
              luctus accumsan tortor posuere ac ut consequat.
            </div>
          </div>
        </div>

        <div
          className="image"
          style={{ background: "#fafafa", paddingBottom: "40px" }}
          data-compid="used-as-headline-1"
        >
          <div className="full-width">
            <div className="image" style={{ display: "flex", height: "500px" }}>
              <img
                alt=""
                src={{ empty_png }}
                style={{
                  height: "500px",
                  width: "1140px",
                  margin: "0 auto 0 auto",
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="component Section" id="display-fonts">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Display fonts</h2>
          </div>
        </div>
        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy spaced-p">
              When creating headlines and subheads display fonts can be used to
              express and idea or moot. Use Toyota Type as the secondary
              typeface for all other copy ie. body copy, legal text etc.
            </div>
          </div>
        </div>

        {/* image block4x4 */}
        <div style={{ background: "white", paddingBottom: "80px" }}>
          <div className="image" style={{ paddingTop: "16px" }}>
            <div className="full-width">
              <div
                className="image"
                style={{ display: "flex", height: "500px" }}
              >
                <div
                  style={{ margin: "auto", width: "1140px", display: "flex" }}
                >
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={{ empty_png }}
                      style={{ height: "500px", paddingRight: "8px" }}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={{ empty_png }}
                      style={{ height: "500px", paddingLeft: "8px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="image" style={{ paddingTop: "16px" }}>
            <div className="full-width">
              <div
                className="image"
                style={{ display: "flex", height: "500px" }}
              >
                <div
                  style={{ margin: "auto", width: "1140px", display: "flex" }}
                >
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={{ empty_png }}
                      style={{ height: "500px", paddingRight: "8px" }}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <img
                      alt=""
                      src={{ empty_png }}
                      style={{ height: "500px", paddingLeft: "8px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end image block4x4 */}
      </section>

      <section className="component Section" id="campaign-colours">
        <div className="section-header darker-background">
          <div className="container">
            <h2 className="ui header">Campaign colours</h2>
          </div>
        </div>
        <div className="blog-content darker-background">
          <div className="container onecolumn">
            <div className="copy spaced-p">
              Campaign colours (along with brand colours) can be used when
              creating communications that sit within Brand Campaigns.
            </div>

            <div className="copy spaced-p">
              Create campaign colours by taking cues from other colours featured
              in the artwork. For example from imagery or textures.
            </div>

            <div className="copy spaced-p">
              These colours can also be used as tints to help ensure there's
              plenty of contrast between key messaging, logos and graphics.
            </div>
          </div>
        </div>

        {/* single image */}
        <div
          className="image"
          style={{ background: "#fafafa", paddingBottom: "40px" }}
          data-compid="used-as-headline-1"
        >
          <div className="full-width">
            <div className="image" style={{ display: "flex", height: "500px" }}>
              <img
                alt=""
                src={{ empty_png }}
                style={{
                  height: "500px",
                  width: "1140px",
                  margin: "0 auto 0 auto",
                }}
              />
            </div>
          </div>
        </div>
        {/* end single image */}
      </section>

      <section className="component Section" id="layout-examples">
        <div
          className="section-header darker-background"
          style={{ paddingBottom: "80px" }}
        >
          <div className="container">
            <h2 className="ui header">Layout examples</h2>
          </div>
        </div>

        <ImageScroller
          style={{ backgroundColor: "white" }}
          images={[
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
            {
              image: {
                svg: empty_png,
                style: { height: "400px", width: "700px" },
              },
              caption: { copy: "Lorem Ipsum" },
            },
          ]}
        ></ImageScroller>
      </section>

      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="Messaging Platform"
        previousUrl="/brandguidelines/messaging-platform"
        nextText="Next"
        nextHeader="Partnerships"
        nextUrl="/brandguidelines/partnerships"
      />
    </Layout>
  );
};

export default BrandCampaigns;
